import { Fragment, useState } from 'react'
import styles from './NewCalculations.module.css'
import {GrClose} from 'react-icons/gr'
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector'
function NewCalculations(props){
  const animation=props.animation
  const [loading,setLoading]=useState(false)
  const [analId,setAnalId]=useState()
  const [allChains,setAllChains]=useState()
  const [pdbId,setPdbId]=useState('')
    return(
        <Fragment>
        {animation == "open" &&
        <div className={`${styles.block} ${styles.open}`}>
            <div className={styles.navbar}>
                <button onClick={props.newCalcChandler} className={styles.closeBut}><GrClose className={styles.exIcon}/></button>
                <div className={styles.steps}>
                    <div className={styles.step}>
                        <div className={styles.stepNumber}>STEP 1</div>
                        {props.step!=1?<div className={styles.prevStepLabel}>Select PDB</div>:<div className={styles.currentStepLabel}>Select PDB</div>}
                        <div className={styles.horizontal}></div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.stepNumber}>STEP 2</div>
                        {props.step==2?<div className={styles.currentStepLabel}>Define Criteria</div>:<div className={styles.stepLabel}>Define Criteria</div>}
                        {props.step==2 &&<div className={`${styles.horizontal} ${styles.second}`}></div>}
                    </div>
                </div>
            </div>
            <div className={styles.meat}>
                {props.step ==1 &&<StepOne setStep={props.setStep} setAnalId={setAnalId} analId={analId} setLoading={setLoading} loading={loading} setAllChains={setAllChains} setPdbId={setPdbId}/>}
                { props.step == 2 && <StepTwo analId={analId} newCalcChandler={props.newCalcChandler} setLoading={setLoading} loading={loading} allChains={allChains} pdbId={pdbId}/>}
            </div>
        </div>}

        {sessionStorage.getItem('newCalc')== "open" &&
        <div className={`${styles.block} `}>
            <div className={styles.navbar}>
                <button onClick={props.newCalcChandler} className={styles.closeBut}><GrClose className={styles.exIcon}/></button>
                <div className={styles.steps}>
                    <div className={styles.step}>
                        <div className={styles.stepNumber}>STEP 1</div>
                        {props.step!=1?<div className={styles.prevStepLabel}>Select PDB</div>:<div className={styles.currentStepLabel}>Select PDB</div>}
                        <div className={styles.horizontal}></div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.stepNumber}>STEP 2</div>
                        {props.step==2?<div className={styles.currentStepLabel}>Define Criteria</div>:<div className={styles.stepLabel}>Define Criteria</div>}
                        {props.step==2 &&<div className={`${styles.horizontal} ${styles.second}`}></div>}
                    </div>
                </div>
            </div>
            <div className={styles.meat}>
                {props.step ==1 &&<StepOne setStep={props.setStep} setAnalId={setAnalId} analId={analId} setLoading={setLoading} loading={loading} setAllChains={setAllChains} setPdbId={setPdbId}/>}
                { props.step == 2 && <StepTwo analId={analId} newCalcChandler={props.newCalcChandler} setLoading={setLoading} loading={loading} allChains={allChains} pdbId={pdbId}/>}
            </div>
        </div>}
        {animation == "close" &&
        <div className={`${styles.block} ${styles.close}`}>
            <div className={styles.navbar}>
                
            </div>
            <div>
            </div>
        </div>}
        </Fragment>

    )
}

export default NewCalculations