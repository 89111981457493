import styles from "./Navbar.module.css"
import logo from "./peptAimlogo.png"
import {FaUserCircle} from 'react-icons/fa'
import NavbarMenuItem from "./NavbarMenuItem"
import { Link } from "react-router-dom"
import {HiOutlineLogout} from 'react-icons/hi'
import { useState } from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { useDispatch } from "react-redux"
import { authActions } from "../store/authSlice"
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { BiMenu } from "react-icons/bi";
import {FaMailchimp} from 'react-icons/fa'
import {BsQuestionLg} from 'react-icons/bs'
function Navbar(props){

    const [display, setDisplay] = useState('default');
    const [align, setAlign] = useState('center');
    const [position, setPosition] = useState('anchor');
    const [viewScroll, setViewScroll] = useState('auto');
    const style = {color:"white", fontSize:"35px"}

    const direction="bottom"
    let activeItem=''
    if (window.location.pathname.split( '/' )[1]==='calculations' )
    {
        activeItem='calculations'
    }
    if (window.location.pathname.split( '/' )[1]==='licenses' )
    {
        activeItem='licenses'
    }

    const [userMenu,setUserMenu]=useState(false)

    function userMenuClick(e){
        setUserMenu(!userMenu)
    }

    const dispatch=useDispatch()
    function logoutChandler(){
       
        dispatch(authActions.setEmail(null))
        dispatch(authActions.setAdmin(null))
        dispatch(authActions.setAccess(null))
        dispatch(authActions.setReload(null))
        dispatch(authActions.setLoged(false))
        localStorage.removeItem('authTokens')
    }


    const user= useSelector((state) => state.auth.email)
    return(
        <div className={styles.block}>
            <img src={logo} className={styles.logo}></img>
            <div className={styles.navbarMenu}>
            <Link to='/calculations'><NavbarMenuItem name="Calculations" activeItem={activeItem} personalItem={'calculations'}/></Link>
               <Link to='/licenses' onClick={props.blockAnimation}> <NavbarMenuItem name="Licenses" activeItem={activeItem} personalItem={'licenses'}/></Link>
            </div>
            <div className={styles.navbarUser}>
                <p className={styles.userP}>{user}</p>
                
            </div>


            <Menu  menuButton={<MenuButton className={styles.userButton}><FaUserCircle className={styles.userIcon}/></MenuButton>}
                key={direction} direction={direction}
                align={align} position={position} viewScroll={viewScroll}
                arrow={display === 'arrow'}
                offsetX={display === 'offset' &&
                    (direction === 'left' || direction === 'right')
                    ? 12 : 0}
                offsetY={display === 'offset' &&
                    (direction === 'top' || direction === 'bottom')
                    ? 12 : 0}>
                <MenuItem ><Link to='/mails' className={styles.mailLink}><FaMailchimp className={styles.Icon}/><div className={styles.Text}>Contact us</div></Link></MenuItem>
                <MenuItem ><BsQuestionLg className={styles.Icon}/><div className={styles.Text}>FAQ</div></MenuItem>
                <MenuItem onClick={e=>{window.location.href="/login"}}><HiOutlineLogout className={styles.logoutIcon}/><div className={styles.logoutText}>Logout</div></MenuItem>

            </Menu>
        </div>
    )
}
export default Navbar