import styles from './Calculations.module.css'
import Calculation from './Calculation'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
function Calculations(props){
    const [realData,setRealData]=useState([])
    const authToken=useSelector((state)=>state.auth.access)
    const [loading,setLoading]=useState(true)
    const [logs,setLogs]=useState()
    const [loadLogs,setLoadLogs]=useState(false)
    sessionStorage.setItem('reloadDetail','open')
    sessionStorage.removeItem('page')


    async function getData(){
        setLoading(true)
        const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calculation/`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

            .then(function(response){
                console.log(response.data)
                setRealData(response.data.map(x=>{
               
                    x.regExp=false
                    x.logs=''
                    return x
                }))
                
                setLoading(false)
            })
            .catch(function(error){
                console.log(error)
            })
            .finally(function(){
                
            })
            return 
    }


    const regExpFunc =(x,log)=>
        {  
            const newState = realData.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj.id == x) {
                 
                    if (obj.regExp==false){
                        return {...obj, regExp: true,logs:log}
                    }
                    else {
                        return {...obj, regExp: false,logs:log}
                    }
                }
               

                // 👇️ otherwise return the object as is
                return obj;
              });
              setRealData(newState)
    }

    
    
    

    useEffect(()=>{
            getData()
        },[]
    )
   
    return(
        <div className={styles.block}>
           
            <div className={styles.firstRow}>
                <div className={styles.tag}>My analyses</div>
                <button onClick={props.newCalcChandler} className={styles.newPredBut}>New analysis</button>
            </div>
            <div className={styles.labelsRow}>
                <div className={styles.nameTag}>NAME</div>
                <div className={styles.dateTag}>DATE</div>
                <div className={styles.statusTag}>STATUS</div>
                <div className={styles.logTag}>LOGS</div>
            </div>
            {loading?   
                <div className={styles.ring}>
                    <div className={styles.loadingTable}>
                    Loading
                    </div>
                </div>:
                <div className={styles.list}>
                     {realData.map((x)=>
                     <div className={styles.singleCalc}>
                         <Calculation name={x.analysis_name} date={x.creation_time} status={x.processed} id={x.id} setLoadLogs={setLoadLogs} loadLogs={x.regExp} regExpFunc={regExpFunc} logs={x.logs}  errors={x.errors}/>
                         {x.regExp &&<div className={styles.logi}> <div >{x.logs.map((log)=><div className={styles.logLine}>{log}</div>)}</div></div>}
                    </div>
                     )}
                </div>
            }
            
            <div className={styles.spaceEnd}></div>

        </div>
    )
}
export default Calculations