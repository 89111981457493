import React, { useEffect, useState } from 'react';
import * as NGL from 'ngl';
import axios from 'axios';
import { useSelector }  from 'react-redux';

const NGLViewer = (props) => {
  const authToken=useSelector((state)=>state.auth.access)
  let folderName=''
  async function loadNGL(){

    
     


    const requestSecond=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${sessionStorage.getItem('fileName')}`, {
      headers:{
          'Content-Type':'application/json',
          'Authorization':"Bearer " + String(authToken)
      }})

      .then(function(response){

        // Create NGL Viewer
    const stage = new NGL.Stage('ngl-container');

    console.log(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${sessionStorage.getItem('fileName')}`)


    var stringBlob = new Blob( [ response.data.file_content], { type: 'text/plain'} );

    stage.loadFile( stringBlob, {ext: "pdb"}).then((component) => {

      const structureComponent = component;

      const reprB = structureComponent.addRepresentation('cartoon', {
        sele: ':B',
        color: 'grey',
      });

    

      const reprA = structureComponent.addRepresentation('ball+stick', {
        sele: ':A',
        // color: 'salmon',
      });

      structureComponent.autoView();

    });

    stage.setParameters({ backgroundColor: 'white' })


          
      })
      .catch(function(error){
          console.log(error)
          console.log(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${props.peptide}`)
      })
      .finally(function(){
          
      })
      return 

    
  }


  async function loadNGLRefresh(){

    
    const requestSecond=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${props.folderName}`, {
      headers:{
          'Content-Type':'application/json',
          'Authorization':"Bearer " + String(authToken)
      }})

      .then(function(response){

        // Create NGL Viewer
    const stage = new NGL.Stage('ngl-container');

    console.log(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${folderName}`)


    var stringBlob = new Blob( [ response.data.file_content], { type: 'text/plain'} );

    stage.loadFile( stringBlob, {ext: "pdb"}).then((component) => {

      const structureComponent = component;

   

      
   

      structureComponent.autoView();

    });

    // stage.setParameters({ backgroundColor: 'black' })


          
      })
      .catch(function(error){
          console.log(error)
          console.log(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${props.peptide}`)
      })
      .finally(function(){
       
      })
      return 

    
  }

  useEffect(() => {
   
    loadNGL()


  }, []);

  
  

  return <div id="ngl-container" style={{ width: '100%', height: '465px' }} />;
};

export default NGLViewer;