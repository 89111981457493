import logo from './logo.png'
import styles from './login.module.css'
import { Link } from 'react-router-dom'
import {HiOutlineMailOpen} from 'react-icons/hi'
import {AiOutlineLock} from 'react-icons/ai'
import axios from 'axios'
import { useDispatch,useSelector} from 'react-redux';
import { authActions } from "../store/authSlice"
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import {useState} from 'react'
function Login(){
    const dispatch = useDispatch()
    const navigate= useNavigate()
    const [loginError,setLoginError]=useState(false)
    async function getToken (e) {
        e.preventDefault()
         setLoginError(false)
         let data=JSON.stringify({'email':e.target.email.value,'password':e.target.password.value})
         
         const requestList=await axios.post(`${process.env.REACT_APP_AXIOS_ID}/api/auth/token/`,data, {
             headers:{
                 'Content-Type':'application/json'
             }
         })
         
             .then(function(response){
            
                dispatch(authActions.setEmail(jwt_decode(response.data.access).email))
                
                dispatch(authActions.setAdmin(jwt_decode(response.data.access).isAdmin))
                
                console.log(response.data)
                dispatch(authActions.setAccess(response.data.access))
                dispatch(authActions.setLoged(true))
                dispatch(authActions.setReload(response.data.refresh))
                
                localStorage.setItem('authTokens', JSON.stringify(response.data))
                
                navigate("/calculations")
             })
             .catch(function(error){
                 setLoginError(true)
             })
             .finally(function(){
                 
             })
             return
     }
return(
    <div className={styles.block}>
        <div className={styles.box}>
            <div className={styles.head}><img className={styles.logo} src={logo}></img></div>
            <div className={styles.choice}>
                <div className={styles.login}>
                    <p  className={styles.loginP}>Login</p>
                    
                    <div className={styles.horizontal}></div>
                </div>
                <Link to='/register' className={styles.registerLink}>
                    <div className={styles.register}>
                        <p>Register</p>
                        <div className={styles.horizontalDe}></div>
                    </div>
                </Link>
            </div>
            
            <form className={styles.form} onSubmit={getToken}>
                <div className={styles.emailRow}>
                    <HiOutlineMailOpen className={styles.emailIcon}/>
                    <input type='email' name='email' className={styles.email} placeholder='yours@example.com' required></input>
                </div>

                <div className={styles.passwordRow}>
                    <AiOutlineLock className={styles.lock}/>
                    <input type='password' name='password' className={styles.password} placeholder='your password' required></input>
                </div>
                {loginError &&<p className={styles.error}>Wrong email or password</p>}
                <Link className={styles.passwordReset} to="/forgot_password"><div >Don't remember your password?</div></Link>
                <button type='submit' className={styles.logBut}>{"LOG IN >"}</button>
                
            </form>
        </div>
    </div>
)
}

export default Login