import styles from "./DragDrop.module.css"
import { useState } from "react";
import { useRef } from "react";
import {AiOutlineCloudUpload} from 'react-icons/ai'
function DragDrop(props){
  const headerRef = useRef(null);
  const fileTypes = ["PDB"];
  const [fileName,setFileName]=useState('')



  const dragOver=(e)=>{
    e.preventDefault()
    const droparea= headerRef.current
    droparea.style.borderColor="green"
  }

  const dragLeave=(e)=>{
    e.preventDefault()
    const droparea= headerRef.current
    droparea.style.borderColor="#e1e1e1"
  }

  const drop=(e)=>{
    e.preventDefault()
    e.stopPropagation();
    const droparea= headerRef.current
    droparea.style.borderColor="#e1e1e1"
    if (e.dataTransfer.files[0].name.split('.').pop()=="pdb") {
      setFileName(e.dataTransfer.files[0].name)
      props.setFile(e.dataTransfer.files[0])
      console.log("poszło")
      props.setFileName(e.dataTransfer.files[0].name)
    }
  }

  const loadFile=(e)=>{
    e.preventDefault()
    e.stopPropagation();
    console.log(e)
    if (e.target.files[0].name.split('.').pop()=="pdb") {
      setFileName(e.target.files[0].name)
      props.setFile(e.target.files[0])
      console.log("poszło")
      props.setFileName(e.target.files[0].name)
    }
  }

  

    return (
        <div className={styles.dragDrop}>
            
            
            <div className={styles.dropper} ref={headerRef} onDragOver={dragOver} onDragLeave={dragLeave} onDrop={drop}>
              <AiOutlineCloudUpload className={styles.uploadIcon}/>
              <label for="file-upload" class="custom-file-upload" className={styles.label}>
                Upload or drag a PDB file 
              </label>
              <input type="file" id="file-upload" multiple={false} accept=".pdb" onChange={loadFile}>
              </input>
              
            </div>
        </div>
    )
}
export default DragDrop