import { configureStore} from '@reduxjs/toolkit'
import authSlice from './authSlice'
import newCalcSlice from './newCalcSlice'
import calcDetSlice from './calcDetSlice'
const store = configureStore({
    reducer:{
            auth:authSlice,
            newCalc:newCalcSlice,
            calcDet:calcDetSlice}
})


export default store