import styles from './register.module.css'
import { Link,useNavigate } from 'react-router-dom'
import {HiOutlineMailOpen} from 'react-icons/hi'
import {AiOutlineLock} from 'react-icons/ai'
import logo from './logo.png'
import axios from 'axios'
import { useState } from 'react'
function Register(){
    const navigate = useNavigate()
    const [conf,setConf]= useState(true)
    const [created,setCreated] = useState(false)
    const [serverError,setServerError]=useState(null)
    async function sendRegister(e){
        e.preventDefault()
        setServerError(null)
        setCreated(false)
        if( e.target.password2.value == e.target.password.value){
        setConf(true)
        let data=JSON.stringify({'email':e.target.email.value,'password':e.target.password.value})
        
        const requestReg=await axios.post(`${process.env.REACT_APP_AXIOS_ID}/api/auth/register/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
                
                setCreated(true)
                navigate('/login')
            })
            .catch(function(error){
                setServerError(error.response.data)
                
            })
            .finally(function(){
                
            })
            return
        }
        else{
            setConf(false)
            return
        }
       
    }
    return(
        <div className={styles.block}>
        <div className={styles.box}>
            <div className={styles.head}><img className={styles.logo} src={logo}></img></div>
            <div className={styles.choice}>
                <Link to='/login' className={styles.loginLink}>
                    <div className={styles.login}>
                        <p  className={styles.loginP}>Login</p>
                    
                        <div className={styles.horizontal}></div>
                    </div>
                </Link>
                <div className={styles.register}>
                    <p>Register</p>
                    <div className={styles.horizontalDe}></div>
                </div>
               
            </div>
            
            <form onSubmit={sendRegister} className={styles.form}>
                <div className={styles.emailRow}>
                    <HiOutlineMailOpen className={styles.emailIcon}/>
                    <input type='email' name='email' className={styles.email} placeholder='yours@example.com' required></input>
                </div>

                <div className={styles.passwordRow}>
                    <AiOutlineLock className={styles.lock}/>
                    <input type="password" name="password" className={styles.password} placeholder='your password' required></input>
                </div>

                <div className={styles.passwordRow2}>
                    <AiOutlineLock className={styles.lock}/>
                    <input type="password" name="password2" className={styles.password} placeholder='repeat your password' required></input>
                </div>
                <div className={styles.errors}>
                                {conf==false && <div className={styles.regTextPas}>Password and password confirmation don't match</div>}
                                {created==true && <div className={styles.regTextPas}>Account created, you can log in now!</div>}
                                {serverError && <div className={styles.regTextPas}>{serverError}</div>}
                </div>
                
                <button type='submit' className={styles.logBut}>{"SIGN UP >"}</button>
                
            </form>
        </div>
    </div>
    )
}
export default Register