import styles from './Licenses.module.css'

function Licenses (){
    
    return (
        <div className={styles.block}>
            <div className={styles.titleRow}>
                <div className={styles.headTitle}>Your licenses</div>
                <div className={styles.headSubTitle}>
                    <div className={styles.greenDot}></div>
                    <div className={styles.headSubTitleText}>You have active licenses </div>
                </div>
            </div>
            <div className={styles.noLicenses}>
                Your licenses will appear here
            </div>
        </div>
    )
}
export default Licenses