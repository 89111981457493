import styles from './CalcDet.module.css'
import { useParams } from 'react-router-dom'
import { useEffect,useState } from 'react'
import axios from 'axios'
import Ligand from './Ligand'
import {BsArrowLeft} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import NGLViewer from './NGLViewer'
import NGLViewerNoSurface from './NGLViewerNoSurface'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch,useSelector} from 'react-redux';
import { calcDetSliceActions } from '../../store/calcDetSlice'
function CalcDet(){
    const dispatch = useDispatch()
    const [surface,setSurface]=useState(true)
    const params=useParams()
    const slug=params.slug
    const authToken=useSelector((state)=>state.auth.access)
    const [pepNames,setPepNames]=useState([])
    const [elecs,setElecs]=useState([])
    const [scores,setScores]=useState([])
    const [vdws,setVdws]=useState([])
    const [data,setData]=useState([])
    // const [peptide,setPeptide]=useState(sessionStorage.getItem('peptide')?sessionStorage.getItem('peptide'):'')
    const [peptide,setPeptide]=useState()
    const [pepLen,setPepLen]=useState('')
    const [analName,setAnalName]=useState('')
    const [mainData,setMainData]=useState()
    const [folderName,setFolderName]=useState()
    const [del,setDel]=useState(false)
    const [pagesList,setPagesList]=useState()
    const [pages,setPages]=useState()
    const [page,setPage]=useState(sessionStorage.getItem('page')?Number(sessionStorage.getItem('page')):1)
    console.log(page)
    async function fetchCalc(){
        const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calculation/${slug}/`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})

            .then(function(response){
                setPages(Math.ceil(response.data.dataframe.length/5))
                setPagesList(Array.from({length: Math.ceil(response.data.dataframe.length/6)}, (_, i) => i + 1).map((x)=>({"label":x,"value":x})))
                console.log(response.data)
                setPepNames(response.data.dataframe.map((x)=>x.peptides_sequence.join('')))
                setData(response.data.dataframe)
                console.log(response.data.dataframe)
                if (sessionStorage.getItem('ligand')!='true'){
                    sessionStorage.setItem('peptide',response.data.dataframe[0].peptides_sequence_one.join(''))
                    sessionStorage.setItem('score',response.data.dataframe[0].scores)
                    sessionStorage.setItem('elec',response.data.dataframe[0].elec)
                    sessionStorage.setItem('vdw',response.data.dataframe[0].vdw)
                    sessionStorage.setItem('fileName',response.data.dataframe[0].Folder_name.split('_')[0])
                }
                setPeptide(sessionStorage.getItem('peptide'))
                setElecs(sessionStorage.getItem('elec'))
                setVdws(sessionStorage.getItem('vdw'))
                setScores(sessionStorage.getItem('score'))
                setPepLen(response.data.pep_len)
                setAnalName(response.data.analysis_name)
                setMainData(response.data)
                
                //  setPeptide(response.data.dataframe[0].peptides_sequence_one.join(''))
                // setElecs(response.data.dataframe[0].elec)
                // setVdws(response.data.dataframe[0].vdw)
                // setScores(response.data.dataframe[0].scores)
                // setPepLen(response.data.pep_len)
                // setAnalName(response.data.analysis_name)
                // setMainData(response.data)
                // setFolderName(response.data.dataframe[0].Folder_name.split('_')[0])
                // sessionStorage.setItem('peptide',response.data.dataframe[0].peptides_sequence_one.join(''))
                // console.log("cwel")
                 sessionStorage.setItem(`folderName${0}`,response.data.dataframe[0].Folder_name.split('_')[0])
                
                  
            })
            .catch(function(error){
                console.log(error)
            })
            .finally(function(){
                if (sessionStorage.getItem('reloadDetail')=="open"){
                    sessionStorage.setItem('reloadDetail',"close")
                    window.location.reload()
                }
            })
            return 
    }
    async function deleteAnal(){
        let deleteData=JSON.stringify({'id':slug})
        const request=await axios.put(`${process.env.REACT_APP_AXIOS_ID}/api/delete`,deleteData, {
           headers:{
               'Content-Type':'application/json',
               'Authorization':"Bearer " + String(authToken)
           }})

           .then(function(response){
               console.log(response)
               window.location=`${process.env.REACT_APP_AXIOS_ID}/calculations`
           })
           .catch(function(error){
               console.log(error)
           })
           .finally(function(){
              
           })
           return 
        }

    // async function getPBD(){
    //     const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${slug}&peptide_number=${sessionStorage.getItem('folderName0')}`, {
    //         headers:{
    //             'Content-Type':'application/json',
    //             'Authorization':"Bearer " + String(authToken)
    //         }})

    //         .then(function(response){
    //             console.log(response.data)
                
    //             if (sessionStorage.getItem('currentPDB')!=response.data.file_content){
    //                 sessionStorage.setItem('currentPDB',response.data.file_content)
    //                 window.location.reload()
    //             }
    //         })
    //         .catch(function(error){
    //             console.log(error)
    //         })
    //         .finally(function(){
                
    //         })
    //         return 
    // }


    

    useEffect(()=>{
        
            fetchCalc()
        
    },[])


    async function downloadZIP(){
        let filename=''
        console.log('testttt')
        const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/download_ZIP?pk=${slug}`, {
            
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            },
            responseType: 'blob'})

            .then(function(response){
                console.log(response)
                return response.data;
            })
            .then(
                blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = analName;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element  
                })
            .catch(function(error){
                console.log(error)
            })
            .finally(function(){
                
            })
            return 
    }


    async function downloadCSV(){
        let filename=''
        const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/downCSV?calculation_id=${slug}`, {
            
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            },
            responseType: 'blob'})

            .then(function(response){
                console.log(response)
                return response.data;
            })
            .then(
                blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');        
                    a.href = url;
                    a.download = analName;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element  
                })
            .catch(function(error){
                console.log(error)
            })
            .finally(function(){
                
            })
            return 
    }

    function surfacer(e){
        setSurface(e.target.checked)
    }

    
    return(
        <div className={styles.block}>
            {del &&
            <div className={styles.modal}>
                <div className={styles.modal_content}>
                    <div className={styles.modalFirstLine}>
                        <div>Are you sure you want to delete this analysys</div>
                    </div>
                    <div className={styles.modalButtons}>
                        <button onClick={deleteAnal} className={`${styles.closeModal} ${styles.delModal}`}>Delete</button>
                        <button onClick={e=>{setDel(false)}} className={`${styles.closeModal}`}>Cancel</button>
                    </div>
                </div>
            </div>}
            <div className={styles.center}>
                <div>
                    <div className={styles.firstRow}>
                        <Link to='/calculations'><button className={styles.emptyBut}><BsArrowLeft className={styles.arrow}/></button></Link>
                        <div className={styles.analName}>{analName}</div>
                        <div className={styles.buttons}>
                            <button className={`${styles.button} ${styles.delete}`} onClick={e=>{setDel(true)}}>Delete analysis</button>
                            
                        </div>
                
                    </div>
                </div>
                <div className={styles.secondRow}>
                    <div className={`${styles.firstBlock} ${styles.smallBlock}`}>
                        <div className={styles.headline}>Identified peptide ligands</div>
                        <div className={styles.smallHeadline}>
                            <div className={styles.pepSeqHead}>PEPTIDE SEQUENCE</div>
                            <div className={styles.pepSeq}>SCORE</div>
                        </div>
                        {data.slice((0+(6*(page-1))),6*page).map((x)=><Ligand page={page} slug={slug} name={x.peptides_sequence_one.join('')} score={x.scores} vdw={x.vdw} elec={x.elec} folder={x.Folder_name.split('_')[0]}  setPeptide={setPeptide}
                        setVdws={setVdws} setScores={setScores} setElecs={setElecs} setFolderName={setFolderName}/>)}
                        <div className={styles.pageButtons}>
                            {page>1?<button onClick={(e)=>setPage(page-1)} className={styles.pageBut}>Previous</button>:<button className={`${styles.pageBut} ${styles.disabled}`}>Previous</button>}
                            <Dropdown options={pagesList} value={page} onChange={(e)=>setPage(e.value)} className={styles.dropdown}/>
                            {page<pages?<button onClick={(e)=>setPage(page+1)} className={styles.pageBut}>Next</button>:<button  className={`${styles.pageBut} ${styles.disabled}`}>Next</button>}
                        </div>

                    </div>
                    <div className={`${styles.secondBlock} ${styles.smallBlock}`} >
                        {surface?<NGLViewer slug={slug} folderName={folderName}/>:<NGLViewerNoSurface slug={slug} folderName={folderName}/>}
                        <div className={styles.switchHolder}>
                            <div>Surface:</div>
                            <label className={styles.switch}>
                                <input type="checkbox" onChange={surfacer} defaultChecked />
                                <span className={`${styles.slider} ${styles.round}`}></span>
                            </label>
                        </div>
                        
                        
                    </div>
                    <div className={`${styles.thirdBlock} ${styles.smallBlock}`}>

                    {mainData!=undefined && mainData.pdb_id &&
                        <div>
                            <div className={styles.headline}>Receptor Details:</div>
                            <div className={styles.thirdRow}>
                                <div className={styles.thirdRowLabel}>PDB ID:</div>
                                <div>{mainData.pdb_id}</div>
                            </div>
                            {mainData.chain!='' &&
                            <div className={styles.thirdRow}>
                                <div className={styles.thirdRowLabel}>Receptor chain:</div>
                                <div>{mainData.chain}</div>
                            </div>
                            }
                        </div>}
                    <div className={styles.headline}>Ligands details:</div>
                        <div className={styles.thirdRow}>
                            <div className={styles.thirdRowLabel}>Peptide sequence:</div>
                            <div>{peptide}</div>
                        </div>
                        <div className={styles.thirdRow}>
                            <div className={styles.thirdRowLabel}>Peptide length:</div>
                            <div>{pepLen}</div>
                            <div className={styles.thirdRowSI}>residues</div>
                        </div>
                        <div className={styles.thirdRow}>
                            <div className={styles.thirdRowLabel}>Affinity score:</div>
                            <div>{Number(scores).toFixed(3)}</div>
                            <div className={styles.thirdRowSI}>points</div>
                        </div>
                        <div className={styles.thirdRow}>
                            <div className={styles.thirdRowLabel}>Binding Energy:</div>
                            <div>{Number(elecs).toFixed(3)}</div>
                            <div className={styles.thirdRowSI}>kJ/mol</div>
                        </div>
                        <div className={styles.thirdRow}>
                            <div className={styles.thirdRowLabel}>VdW Energy:</div>
                            <div>{Number(vdws).toFixed(3)}</div>
                            <div className={styles.thirdRowSI}>kJ/mol</div>
                        </div>
                        <div className={styles.downloadButtons}>
                            <button onClick={downloadCSV}  className={styles.button}>Download all results</button>
                            <button onClick={downloadZIP} className={styles.button}>Download unit files</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalcDet