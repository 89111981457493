import styles from './MailForm.module.css'
import axios from 'axios'
import { useSelector } from 'react-redux'
function MailForm(){
    const authToken=useSelector((state)=>state.auth.access)
    async function sendMail(e){
        let data=JSON.stringify({'email':e.target.email.value,'message':e.target.message.value,'subject':e.target.subject.value})

        const request=await axios.post(`${process.env.REACT_APP_AXIOS_ID}/api/sendMail`,data, {
           headers:{
               'Content-Type':'application/json',
               'Authorization':"Bearer " + String(authToken)
           }})

           .then(function(response){
               console.log(response)
           })
           .catch(function(error){
               console.log(error)
           })
           .finally(function(){
              
           })
           return 
        }
    return(
        <div className={styles.block}>
            <div className={styles.header}>CONTACT US</div>

            <div className={styles.text}>You can contact us via the below contact form.</div>
            <form className={styles.form} onSubmit={sendMail}>
                <div className={styles.firstBlock} >
                    <div>
                        <div className={styles.label}>Name</div>
                        <input placeholder='Name' className={styles.inputy} id="subject"  required></input>
                    </div>
                    <div>
                        <div className={styles.label}>Email address</div>
                        <input placeholder='Email' className={styles.inputy} id="email" required></input>
                    </div>
                    <div>
                        <div className={styles.label}>Category</div>
                        <input placeholder='Pick category' className={styles.inputy}></input>
                    </div>
                </div>
                <div className={styles.secondBlock}>
                    <div>
                        <div className={styles.label}>Message</div>
                        <textarea placeholder='Your message' className={styles.textArea} id="message" required></textarea>
                    </div>
                    <button className={styles.sendButton} type='submit'>Send</button>
                </div>
                
            </form>
        </div>
    )
}
export default MailForm