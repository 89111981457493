import logo from './logo.svg';
import './App.css';
import { Fragment, useState} from 'react';
import {Routes,Route,Navigate} from "react-router-dom";
import Navbar from './Navbar/Navbar';
import Calculations from './Calculations/Calculations';
import Login from './Authorization/login';
import Register from './Authorization/register';
import Forgot from './Authorization/forgot';
import {useSelector} from 'react-redux';
import ResetPassword from './Authorization/resetPassword';
import NewCalculations from './Calculations/NewCalculations/NewCalculations';
import styles from './App.module.css'
import CalcDet from './Calculations/CalcDet/CalcDet';
import MailForm from './MailForm/MailForm';
import Licenses from './Licenses/Licenses';
function App() {
  const user= useSelector((state) => state.auth.email)
  const isAdmin= useSelector((state) => state.auth.isAdmin)
  const state=useState()
  const [newCalc,setNewCalc]=useState(sessionStorage.getItem('animation'))
  const [animation,setAnimation]=useState('')
  const [step,setStep]=useState(1)
  if (sessionStorage.getItem("step") && step!=sessionStorage.getItem("step")){
    setStep(sessionStorage.getItem("step"))
  }
  if (sessionStorage.getItem("animation") && animation!=sessionStorage.getItem("animation")){
    setAnimation(sessionStorage.getItem("animation"))
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  async function endAnim(){
    await delay(1000)
    sessionStorage.setItem("animation", "");
    setAnimation(sessionStorage.getItem('animation'))}
  async function newCalcChandler(){
        
        if (sessionStorage.getItem('animation')=='open'){
          console.log('close')
          sessionStorage.setItem("animation", "close");
          setAnimation(sessionStorage.getItem('animation'))
          sessionStorage.setItem("step", "1");
          sessionStorage.setItem("newCalc",'')
          endAnim()
        }
        else if (sessionStorage.getItem('animation')=='close'){
          sessionStorage.setItem("animation", "open");
          setAnimation(sessionStorage.getItem('animation'))
          sessionStorage.setItem('stepChecker',0)
        }

        else if (sessionStorage.getItem('animation')=='' && sessionStorage.getItem('newCalc')=='open'){
          window.location.reload()
          console.log('close')
          sessionStorage.setItem("animation", "close");
          endAnim()
          sessionStorage.setItem("step", "1");
          sessionStorage.setItem("newCalc",'')
          sessionStorage.setItem("test",'test3')
          
        }
        else if (!sessionStorage.getItem('animation')){
          sessionStorage.setItem("animation", "open");
          setAnimation(sessionStorage.getItem('animation'))
          sessionStorage.setItem('stepChecker',0)
        }
        
    }


  function blockAnimation(){
    setAnimation('')
    sessionStorage.setItem('animation','')
    console.log(animation)
  }

  
  return (
      <Fragment>
        <Routes> 
          <Route path="" element={<Navigate to='login'/>}></Route>
          <Route path="login" element={<Login/>}></Route>
          <Route path="register" element={<Register/>}></Route>
          <Route path="forgot_password" element={<Forgot/>}></Route>
          <Route path="reset_password" element={<ResetPassword/>}></Route>
          <Route path="calculations" element={!user ? <Navigate replace to ="/login"/> :
            <div >
              <NewCalculations newCalcChandler={newCalcChandler} step={step} setStep={setStep} animation={animation}/>
              <Navbar blockAnimation={blockAnimation}/>
              <Calculations newCalcChandler={newCalcChandler} animation={animation}/>
            </div>}>
          </Route>
          <Route path="calculation/:slug" element={!user ? <Navigate replace to ="/login"/> :
            <div >
              <Navbar blockAnimation={blockAnimation}/>
              <CalcDet />
            </div>}>
          </Route>
          <Route path="licenses" element={!user ? <Navigate replace to ="/login"/> :<div className='calcList'><Navbar /><Licenses/></div>}></Route>
          <Route path="mails" element={!user ? <Navigate replace to ="/login"/> :<div className='calcList'><Navbar /><MailForm/></div>}></Route>
        </Routes>
      </Fragment>
      
     

  );
}

export default App;
