import {createSlice} from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const initialState={
    email:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).email:null,
    loged:false,
    coins:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).tokens:null,
    access:localStorage.getItem('authTokens')? JSON.parse(localStorage.getItem('authTokens')).access:null,
    reload:localStorage.getItem('authTokens')? JSON.parse(localStorage.getItem('authTokens')).refresh:null,
    isAdmin:localStorage.getItem('authTokens')? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access).isAdmin:null
}

const authSlice = createSlice({
    name: "auth",
    initialState:initialState,
    reducers:{
        setEmail(state,value){
            state.email=value.payload
        },
        setAccess(state,value){
            state.access=value.payload
        },
        setReload(state,value){
            state.reload=value.payload
        },
        setLoged(state,value){
            state.loged=value.payload
        },
        setAdmin(state,value){
            state.isAdmin=value.payload
        }
    }
})

export const authActions =authSlice.actions
export default authSlice.reducer