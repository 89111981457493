import styles from './forgot.module.css'
import logo from './logo.png'
import { Link } from 'react-router-dom'
import {HiOutlineMailOpen} from 'react-icons/hi'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { useState } from 'react'
import axios from 'axios'
function Forgot(){
    const [emailError,setEmailError]=useState()
    const [succes,setSucces]= useState(false)
    async function submiter(e){
        e.preventDefault()
        let data=JSON.stringify({'email':e.target.email.value})
        setSucces(false)
        setEmailError()
        const requestList=await axios.post(`${process.env.REACT_APP_AXIOS_ID}/api/auth/password_reset/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
                setSucces(true)
            })
            .catch(function(error){
                setEmailError(error.response.data.email)
            })
            .finally(function(){
                
            })
            return
    }
    return(
        <div className={styles.block}>
            <div className={styles.box}>
                <div className={styles.head}>
                   <Link to="/login"> <div className={styles.backCycle}><AiOutlineArrowLeft className={styles.backArrow}/></div></Link>
                    <img className={styles.logo} src={logo}></img>
                    <p className={styles.headText}>Reset Your password</p>
                </div>
                
                <p className={styles.text}>Please enter your email address. We will send you an email to reset your password.</p>
                <form className={styles.form} onSubmit={submiter}>
                    <div className={styles.emailRow}>
                        <HiOutlineMailOpen className={styles.emailIcon}/>
                        <input name='email' type='email' className={styles.email} placeholder='yours@example.com' required></input>
                    </div>

                    {emailError && <div className={styles.forgotError}>{emailError}</div>}
                    {succes && <div className={styles.forgotError}>Mail with further instructions has been sent</div>}
            
                    <button type='submit' className={styles.logBut}>{"SEND EMAIL >"}</button>
                
                </form>
            </div>
        </div>
    )

}
export default Forgot