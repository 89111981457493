import styles from './NavbarMenuItem.module.css'
function NavbarMenuItem(props){
    return(
        <div className={styles.block}>
            <div className={styles.label}>{props.name}</div>
           {props.activeItem==props.personalItem && <div className={styles.underline}></div>}
            
        </div>
    )
}

export default NavbarMenuItem