import logo from './logo.png'
import styles from './resetPassword.module.css'
import { Link } from 'react-router-dom'
import {HiOutlineMailOpen} from 'react-icons/hi'
import {AiOutlineLock} from 'react-icons/ai'
import axios from 'axios'
import { useDispatch,useSelector} from 'react-redux';
import { authActions } from "../store/authSlice"
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import {useState} from 'react'
function ResetPassword(){
    const dispatch = useDispatch()
    const navigate= useNavigate()

    const [loginError,setLoginError]=useState(false)
    const [conf,setConf]= useState(true)
    const [passwordErorr,setPasswordError]=useState()
    const [success,setSucces] = useState(false)
    
    async function sendPasswordReset(e){
        e.preventDefault()
        setLoginError(false)
        setConf(false)
        setPasswordError()
        setSucces(false)
        if( e.target.password2.value == e.target.password.value){
        setConf(true)
        let data=JSON.stringify({'token':e.target.token.value,'password':e.target.password.value})
        const requestList=await axios.post(`${process.env.REACT_APP_AXIOS_ID}/api/auth/password_reset/confirm/`,data, {
            headers:{
                'Content-Type':'application/json'
            }
        })
        
            .then(function(response){
                setSucces(true)
            })
            .catch(function(error){
               console.log(error.response.data)
                setLoginError(true)
                if (error.response.data.password){
                    if (error.response.data.password[0]=="This password is too short. It must contain at least 8 characters."){
                        setLoginError(false)
                        setPasswordError(error.response.data.password[0])}
                
                    }
                })
            .finally(function(){
                
            })
            return
        }
    }
return(
    <div className={styles.block}>
        {!success ?<div className={styles.box}>
            <Link to='/login' className={styles.headLogin}><div className={styles.head}><img className={styles.logo} src={logo}></img></div></Link>
            <p className={styles.text}>Change your password</p>
            
            <form className={styles.form} onSubmit={sendPasswordReset}>
                <div className={styles.emailRow}>
                    <HiOutlineMailOpen className={styles.emailIcon}/>
                    <input  name='token' className={styles.email} placeholder='Password change token' required></input>
                </div>

                <div className={styles.passwordRow}>
                    <AiOutlineLock className={styles.lock}/>
                    <input type='password' name='password' className={styles.password} placeholder='your new password' required></input>
                </div>

                <div className={styles.passwordRow2}>
                    <AiOutlineLock className={styles.lock}/>
                    <input type='password' name='password2' className={styles.password} placeholder='repeat your new password' required></input>
                </div>
            
                {loginError && <div className={styles.resetError}>Wrong key</div>}
                {passwordErorr == "This password is too short. It must contain at least 8 characters." && <div className={styles.resetError}>This password is too short. It must contain at least 8 characters.</div>}
                {conf==false && <div className={styles.resetError}>Passwords do not match</div>}
                <button type='submit' className={styles.logBut}>{"SET NEW PASSWORD >"}</button>
                
            </form>
            

        </div>:
        <div className={styles.box2}>
            <div className={styles.head}><img className={styles.logo} src={logo}></img></div>
            <div className={styles.resetError}>Password changed with succes, you can <Link to='/login' className={styles.logIn}>log in now!</Link></div>
        </div>
        }
    </div>
)
}

export default ResetPassword