import styles from './Ligand.module.css'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


function Ligand(props){
    const selector=useSelector((state)=>state.calcDet.ligandName)
  
    const [checker,setChecker]=useState()
    const [active,setActive]=useState(checker==props.name?true:false)
    const authToken=useSelector((state)=>state.auth.access)

    if (sessionStorage.getItem('peptide')!=checker &&sessionStorage.getItem('peptide')==props.name){
        setActive(true)
        setChecker(sessionStorage.getItem('peptide'))
    }
    if (sessionStorage.getItem('peptide')!=checker &&sessionStorage.getItem('peptide')!=props.name){
        setActive(false)
        setChecker(sessionStorage.getItem('peptide'))
    }

    useEffect(() => {
        setChecker()
        if (sessionStorage.getItem('peptide')!=checker &&sessionStorage.getItem('peptide')==props.name){
            setActive(true)
            setChecker(sessionStorage.getItem('peptide'))
        }
        if (sessionStorage.getItem('peptide')!=checker &&sessionStorage.getItem('peptide')!=props.name){
            setActive(false)
            setChecker(sessionStorage.getItem('peptide'))
        }
        console.log(props.page)
    },[props.page]
    )

async function getPBD(){
    const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calcPepVis?calculation_id=${props.slug}&peptide_number=${props.folder}`, {
        headers:{
            'Content-Type':'application/json',
            'Authorization':"Bearer " + String(authToken)
        }})
        .then(function(response){
            console.log(response.data)
            
             if (sessionStorage.getItem('currentPDB')!=response.data.file_content){
                 sessionStorage.setItem('ligand','true')
                 sessionStorage.setItem("peptide",props.name)
                 sessionStorage.setItem('currentPDB',response.data.file_content)
                 sessionStorage.setItem('score',props.score)
                 sessionStorage.setItem('elec',props.elec)
                 sessionStorage.setItem('vdw',props.vdw)
                 sessionStorage.setItem('fileName',props.folder)
                 sessionStorage.setItem('page',props.page)
                 window.location.reload()
    
             }
            
            
            
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(){
            console.log(checker)
        })
        return 
    }
return(
    <button className={`${styles.block} ${active && styles.active}`} onClick={getPBD}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.score}>{props.score.toFixed(2)}</div>
    </button>
)
}
export default Ligand