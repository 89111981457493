import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import store from './store/index'
import { Provider } from 'react-redux';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import "primereact/resources/primereact.min.css";
import { Helmet } from "react-helmet";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
   
      <App />
      
    </BrowserRouter>
    <Helmet>
      <script src="https://3Dmol.org/build/3Dmol-min.js"></script>
      {/*<script src="https://3Dmol.org/build/3Dmol.ui-min.js"></script>  */}
      </Helmet>
  </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
