import {createSlice} from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const initialState={
    ligandName:''
}

const calcDetSlice = createSlice({
    name: "calcDet",
    initialState:initialState,
    reducers:{
        setLigandName(state,value){
            state.ligandName=value.payload
            }
        }
    }
    )

export const calcDetSliceActions=calcDetSlice.actions
export default calcDetSlice.reducer