import styles from "./stepOne.module.css"
import { useState } from "react";
import DragDrop from "./DragDrop";
import axios from "axios";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { newCalcSliceActions } from "../../store/newCalcSlice";
import { FileUploader } from "react-drag-drop-files";
function StepOne(props){
    const dispatch = useDispatch()
    const [fileName,setFileName]=useState()
    const [file,setFile]=useState()
    const [validator,setValidator]=useState()
    const [analName,setAnalName]=useState()
    const authToken=useSelector((state)=>state.auth.access)
    let analId=''
    let errorVal=''
    const link=`${process.env.REACT_APP_AXIOS_ID}/api/calculation/load/`

    
    async function load (e) {
        sessionStorage.removeItem("chain")
        sessionStorage.removeItem("pepLen")
        sessionStorage.removeItem("librarySize")
         e.preventDefault()
         if (file==undefined && e.target.pdb_id.value=='' ){
               setValidator("Insert PDB ID or upload a PDB file")
            }
        else{
            sessionStorage.setItem('loadingNewAnal','loading')
            props.setLoading(true)
            let data=null
            let header={
               'Content-Type':'application/json',
               'Authorization':"Bearer " + String(authToken)
            }   
            let formData=new FormData()
            if (file==undefined){
                data=JSON.stringify({'pdb_id':e.target.pdb_id.value,'analysis_name':e.target.name.value})
                console.log('noFile')
            }
            else{
                console.log('file2')
                header={
                   'Content-Type':'multipart/form-data',
                   'Authorization':"Bearer " + String(authToken)
               }
               formData.append('pdb_file',file)
               formData.append('analysis_name',e.target.name.value)
               console.log(formData)
               data=formData
            }

            const requestList=await axios.post(link,data, {
               headers:header})
            
                .then(function(response){
                  
                  
                        console.log(response)
                        props.setAnalId(response.data.id)
                        sessionStorage.setItem('analId',response.data.id)
                        analId=(response.data.id)
                        sessionStorage.setItem('animation','')
                        sessionStorage.setItem("newCalc", "open")
                  
                   
                
                })
                .catch(function(error){
                   console.log(error)
                   props.setLoading(false)
                   
                    setValidator("Invalid pdb file. Try again!")
                    errorVal='error'
                    console.log(errorVal)
                   
                   
                })
                .finally(function(){
                    if(errorVal==''){
                        fetchCalc()
                        props.setStep(2)
                        sessionStorage.setItem("step", "2");
                    }
                })
        
                return
        }
            }

    async function fetchCalc(){
        console.log(analId)
            const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calculation/${analId}/`, {
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':"Bearer " + String(authToken)
                }})
                .then(function(response){
                    
                   
                    
                    console.log(response.data)
                    sessionStorage.setItem('allChains',response.data.pep_chains)
                    sessionStorage.setItem('pdbID',response.data.pdb_id)
                    sessionStorage.setItem('analName',analName)
                    // if (sessionStorage.getItem('structure')!=response.data.structure){
                    //     sessionStorage.setItem('structure',response.data.structure)
                    //     sessionStorage.setItem('loadingNewAnal','loaded')
                        
                    // }

                    sessionStorage.setItem('loadingNewAnal','loaded')
                    if (sessionStorage.getItem('stepChecker')==0){
                        sessionStorage.setItem('stepChecker',1)
                        window.location.reload()
                    }
                    
                })
                .catch(function(error){
                    console.log(error)
                })
                .finally(function(){
                    
                })
                return 
        }
    return(
        <div className={styles.block}>
            {props.loading ? 
                <div className={styles.ring}>
                    <div className={styles.loadingTable}>
                    Loading
                    </div>
                </div>:
                <form onSubmit={load}>
                    <p className={styles.bolder}>Analysis name</p>
                    <div>
                       <input placeholder="Insert analysis name" className={styles.input} id="name" required onChange={(e)=>setAnalName(e.target.value)}/>
                    </div>
                    <p className={styles.bolder}>Search protein by PDB</p>
                    <div>
                    
                       <input placeholder="Insert PDB ID" className={styles.input} id="pdb_id" defaultValue=''/>
                    </div>
                    <p className={`${styles.bolder} ${styles.uploadTag}`}>or upload file</p>
                    <DragDrop setFile={setFile} setFileName={setFileName}/>
                    {fileName&&<div className={styles.fileName}>*{fileName}</div>}
                    <button type="submit" className={styles.submitBut}>Start</button>
                    {validator && <div className={styles.validatorBox}><div className={styles.validator}>{validator}</div></div>}
                </form>
                
            }
        </div>
    )
}
export default StepOne