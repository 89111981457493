import styles from './Calculation.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { IoMdArrowDropdown } from "react-icons/io"
import { IoMdArrowDropup } from "react-icons/io";
import { useState } from 'react'
function Calculation(props){

    const authToken=useSelector((state)=>state.auth.access)

    const date= new Date(props.date)
    console.log(date)
    const hour=date.getHours()
    const minute= date.getMinutes()<10?'0'+ date.getMinutes():''+ date.getMinutes()
    const day= date.getDate()
    console.log(day)
    const month= date.getMonth()+1
    const year= date.getFullYear()

    
    
    function setStartingSession(){
        sessionStorage.setItem('currentPDB','')
        sessionStorage.setItem('ligand','')
    }

    async function getLogs(x){
        if (props.loadLogs==false){
            props.setLoadLogs(true)
            const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/get_log?id=${props.id}`, {
               headers:{
                   'Content-Type':'application/json',
                   'Authorization':"Bearer " + String(authToken)
               }})

               .then(function(response){
                props.regExpFunc(x,response.data)

               })
               .catch(function(error){
                   console.log(error)
               })
               .finally(function(){
                
               })
               return 
        }
        else{
            props.setLoadLogs(false)
            props.regExpFunc(x,'')
            
           
        }
        }
    return(
        <div className={styles.block}>
            {!props.errors ?
                !props.status ?
                <div className={styles.calculationBlock}>
                    <div className={styles.calculation}>
                        <div className={styles.name}>{props.name}</div>
                        <div className={styles.date}>{day}-{month}-{year} {hour}:{minute}</div>
                        <div className={styles.status}>
                            <div className={styles.false}>IN PROGRESS</div>
                        </div>

                    </div>
                    {props.loadLogs ? <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropup className={styles.arrow}/></button>:
                        <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropdown className={styles.arrow}/></button>}
                </div>:
                <div className={styles.calculationBlock}>
                    <Link to={`/calculation/${props.id}`}  onClick={setStartingSession} className={styles.calculation}>
                        <div className={styles.name}>{props.name}</div>
                        <div className={styles.date}>{day}-{month}-{year} {hour}:{minute}</div>
                        <div className={styles.status}>
                            <div className={styles.true}>DONE</div>
                        </div>
                    </Link>
                    {props.loadLogs ? <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropup className={styles.arrow}/></button>:
                    <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropdown className={styles.arrow}/></button>}

                </div>
                :
            <div className={styles.calculationBlock}>
                <div className={styles.calculation}>
                    <div className={styles.name}>{props.name}</div>
                    <div className={styles.date}>{day}-{month}-{year} {hour}:{minute}</div>
                    <div className={styles.status}>
                        <div className={styles.error}>Error</div>
                    </div>

                </div>
                {props.loadLogs ? <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropup className={styles.arrow}/></button>:
                    <button className={styles.logBut} onClick={()=>getLogs(props.id)}><IoMdArrowDropdown className={styles.arrow}/></button>}
            </div>
            }
            
        </div>
    )
}

export default Calculation