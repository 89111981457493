import {createSlice} from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const initialState={
    step:1
}

const newCalcSlice = createSlice({
    name: "auth",
    initialState:initialState,
    reducers:{
        setStep(state,value){
            state.step=value.payload
            }
        }
    }
    )

export const newCalcSliceActions =newCalcSlice.actions
export default newCalcSlice.reducer