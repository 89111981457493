import React, { useEffect, useState } from 'react';
import * as NGL from 'ngl';
import axios from 'axios';
import { useSelector }  from 'react-redux';

const NGLViewer = (props) => {
  const authToken=useSelector((state)=>state.auth.access)
  const analId=sessionStorage.getItem('analId')
  console.log(props.chain)
  const [chained,setChained]=useState(props.pdb)
  const chainer=(data)=>{
        
    
    return(data.split('\n').filter((word)=>word.substring(0,4)=="ATOM").filter((word)=>word.charAt(21)==props.chain).join("\n"))

}
async function fetchCalc(){
  console.log(analId)
      const request=await axios.get(`${process.env.REACT_APP_AXIOS_ID}/api/calculation/${analId}/`, {
          headers:{
              'Content-Type':'application/json',
              'Authorization':"Bearer " + String(authToken)
          }})
          .then(function(response){
              
            console.log(response.data)
              
            let finalPDB=response.data.structure
            if (props.chain!=""){
              finalPDB=chainer(response.data.structure)
            }
            let test=chainer(response.data.structure)
            console.log(test)
            // Create NGL Viewer
            const stage = new NGL.Stage('ngl-container');
        
            
        
        
            var stringBlob = new Blob( [ finalPDB ], { type: 'text/plain'} );
        
            stage.loadFile( stringBlob, {ext: "pdb"}).then((component) => {
        
              const structureComponent = component;
        
              const reprB = structureComponent.addRepresentation('cartoon', {
                color: 'grey',
              });
        
              
        
              structureComponent.autoView();
        
            });
        
            stage.setParameters({ backgroundColor: 'white' })
            

              
              
          })
          .catch(function(error){
              console.log(error)
          })
          .finally(function(){
              
          })
          return 
  }
  useEffect(() => {
   
    fetchCalc()


  }, []);
  

  return <div id="ngl-container" style={{ width: '100%', height: '425px' }} />;
};

export default NGLViewer;