import styles from './stepTwo.module.css'
import protein from './protein.png'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Dropdown } from 'primereact/dropdown';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import "primereact/resources/primereact.min.css"; 
import "primeicons/primeicons.css"
import {BsFillCheckCircleFill} from 'react-icons/bs'
import { Checkbox } from 'primereact/checkbox';
import NGLViewer from './NGLViewer'
import NGLViewerNoSurface from './NGLViewerNoSurface'
function StepTwo(props){
    const analName=sessionStorage.getItem('analName')?sessionStorage.getItem('analName'):""
    const [surface,setSurface]=useState(true)
    const [peptideLen,setPeptideLen]=useState(sessionStorage.getItem('pepLen')?sessionStorage.getItem('pepLen'):"4")
    const [id,setId]=useState(props.analId)
    const [sendMail,setSendMail]=useState(false)
    const allChains=sessionStorage.getItem('allChains').split(',').map((x)=>(x!=''?{label:x,value:x}:{label:'All chains',value:x}))
   
   
    const pdbId=sessionStorage.getItem('pdbID')
    if (id==undefined && id!=sessionStorage.getItem('analId')){
    setId(sessionStorage.getItem('analId'))
    }
    if (sessionStorage.getItem('loadingNewAnal')=='loaded'){
        props.setLoading(false)
    }
    const authToken=useSelector((state)=>state.auth.access)
    const [chain,setChain]=useState(sessionStorage.getItem('chain')?sessionStorage.getItem('chain'):"")
    const librarySizeOptions=[{label:"50 000",value:"5k"},{label:"100 000",value:"10k"},{label:"500 000",value:"50k"},{label:"1 000 000",value:"100k"}]
    const [librarySize,setLibrarySize]=useState(sessionStorage.getItem('librarySize')?sessionStorage.getItem('librarySize'):"5k")
    const [success,setSuccess]=useState(false)
    
    if (sessionStorage.getItem('stepChecker')!=1){
        sessionStorage.setItem('stepChecker',0)
    }
    

    async function sendCalc(e){
        e.preventDefault()
        let header={
            'Content-Type':'application/json',
            'Authorization':"Bearer " + String(authToken)
        }
       
        
        let data=JSON.stringify({'id':id,'chain':e.target.chain.value,'pep_len':e.target.length.value,'library_len':e.target.library.value,'sendMail':sendMail})

         console.log(data)
         
         const requestList=await axios.put(`${process.env.REACT_APP_AXIOS_ID}/api/update/`,data, {
            headers:header})
         
             .then(function(response){
                console.log('success')
                setSuccess(true)
               
             })
             .catch(function(error){
                console.log(error)
             })
             .finally(function(){
                 
             })
             return
        
    }

    function changeChain(e){
        setChain(e.value)
        sessionStorage.setItem('chain',e.value)
        window.location.reload()
    }

    function changeLibrarySize(e){
        setLibrarySize(e.value)
        sessionStorage.setItem('librarySize',e.value)
    }

    function changePepLen(e){
        setPeptideLen(e.target.value)
        console.log(e.target.value)
        sessionStorage.setItem('pepLen',e.target.value)
    }

    function surfacer(e){
        setSurface(e.target.checked)
    }

    
    return(
        <div className={styles.block}>
            
            {success &&
            <div className={styles.modal}>
                <div className={styles.modal_content}>
                    <div className={styles.modalFirstLine}>
                        <div>Analysis started successfully</div>
                    </div>
                    <button onClick={props.newCalcChandler} className={styles.closeModal}>Continue</button>
                </div>
            </div>}
            
            
            

            <div className={styles.calcBox}>
                <div className={styles.calcTag}>Define analysis criteria</div>
                <form onSubmit={sendCalc}>
                    <div className={styles.calcFormVar}>
                        <div  className={styles.varLabel}>Peptide length:</div>
                        <input type="number" min='4' max='12' className={`${styles.dropdown} ${styles.pepLen}`} name="length" defaultValue={peptideLen} required onChange={changePepLen}></input>
                    </div>
                    <div className={styles.calcFormVar}>
                        <div className={styles.varLabel}>Library size:</div>
                        <Dropdown options={librarySizeOptions} value={librarySize} onChange={changeLibrarySize} className={styles.dropdown} name="library"/>
                    </div>
                    <div className={styles.calcFormVar}>
                        <div className={styles.varLabel}>Chain:</div>
                      <Dropdown options={allChains} value={chain} onChange={changeChain} className={styles.dropdown} name="chain"/>
                    </div>

                    <div className={styles.notificationRow}>
                        <div className={styles.varLabel}>Get notification on email, after the calculation is done: <Checkbox className={`${sendMail?styles.mypanel:styles.myPanelOff}`} onChange={e => setSendMail(e.checked)} checked={sendMail}></Checkbox></div>
                    </div>
                    <button type='submit' className={styles.submitBut}>Start Analysis</button>
                </form>
            </div>

            <div className={styles.proteinBox}>
                {pdbId!='null' ?<div className={styles.imageDesc}><div>{pdbId}</div>{chain && <div>:{chain}</div>}</div>: <div className={styles.imageDesc}>{analName}{chain && <div>:{chain}</div>}</div>}
                {surface ? <NGLViewer pdb='' chain={chain}/> :<NGLViewerNoSurface pdb='' chain={chain}/>}
                <div className={styles.switchHolder}>
                    <div>Surface:</div>
                    <label className={styles.switch}>
                        <input type="checkbox" onChange={surfacer} defaultChecked />
                        <span className={`${styles.slider} ${styles.round}`}></span>
                    </label>
                </div>
               

            </div>
                
        </div>
    )
}
export default StepTwo